import React, { FunctionComponent, SetStateAction } from 'react';
import { ChipProps, SvgIconTypeMap, TableCellProps } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { TablerIcon } from '@tabler/icons';

import { Model, Product } from './customer';

export interface ColorProps {
  readonly [key: string]: string;
}

export type LinkTarget = '_blank' | '_self' | '_parent' | '_top';

export interface NavItemType {
  id?: string;
  icon?: GenericCardProps['iconPrimary'];
  target?: boolean;
  external?: string;
  url?: string | undefined;
  type?: string;
  title?: React.ReactNode | string;
  color?: 'primary' | 'secondary' | 'default' | undefined;
  caption?: React.ReactNode | string;
  breadcrumbs?: boolean;
  disabled?: boolean;
  chip?: ChipProps;
}

export interface GenericCardProps {
  title?: string;
  primary?: string | number | undefined;
  secondary?: string;
  content?: string;
  image?: string;
  dateTime?: string;
  iconPrimary?: OverrideIcon;
  color?: string;
  size?: string;
}

export type OverrideIcon =
  | (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
      muiName: string;
    })
  | React.ComponentClass<any>
  | FunctionComponent<any>
  | TablerIcon;

export interface KeyedObject {
  [key: string]: string | number | KeyedObject | any;
}

export type ArrangementOrder = Order.asc | Order.desc | undefined;

export interface EnhancedTableHeadProps extends TableCellProps {
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  order: ArrangementOrder;
  orderBy?: string;
  numSelected: number;
  rowCount: number;
  onRequestSort: (e: React.SyntheticEvent, p: string) => void;
}

export interface EnhancedTableToolbarProps {
  setChecked: any;
  isModelSetupModalOpen: any;
  setIsModelSetupModalOpen: any;
  fileSize: any;
  rowData: any;
  rowDataStatus: any;
  setRowDataStatus: any;
  setRowData: any;
  data: any;
  isModels: boolean;
  selected: any[];
  setSelected?: any;
  numSelected: number;
  actions: IDashboardAction[];
  setActions?: (value: any) => void;
  setProcessing?: (value: any) => void;
  predictionTraining?: boolean;
  setPredictionTraining?: (value: any) => void;
  predictionProgress?: number;
  setPredictionProgress?: (value: any) => void;
  row?: any;
}

export interface IDashboardProps {
  setUserModelsArray?: any;
  rowDataStatus?: any;
  setRowDataStatus?: any;
  a?: boolean;
  setA?: any;
  isComp?: boolean;
  isModels: boolean;
  analyzeManagerTableData?: any[];
  setAnalyzeManagerTableData?: (params: any) => any;
  analyzeManagerChartData?: any;
  setAnalyzeManagerChartData?: (params: any) => any;
  minimize: boolean;
  title: string;
  data: any;
  tableHeaders: HeadCell<Product & Model>[];
  actions: IDashboardAction[];
  hasUpload?: boolean;
  setActions?: (param: any) => void;
  setNewArray?: (param: any) => void;
  searchProps: string[];
  setX?: any;
  predictionTraining?: boolean;
  setPredictionTraining?: (value: any) => void;
  predictionProgress?: number;
  setPredictionProgress?: (value: any) => void;
}
export interface IProgressProps {
  progress?: any;
  uploadStatus?:any;
  setProgress?: any;
  predictionProgress?: number;
  setPredictionProgress?: (value: any) => void;
}

export interface IAnalyzeDashboardData {
  analyzeManagerTableData?: any[];
  setAnalyzeManagerTableData?: (params: any) => any;
  analyzeManagerChartData?: any[];
  setAnalyzeManagerChartData?: (params: any) => any;
}
export interface IAnalyzeChartData {
  isSum?: boolean;
  modelSummaryData: any;
  analyzeManagerChartData?: any;
  setAnalyzeManagerChartData?: (params: any) => any;
}

export enum Order {
  asc = 'asc',
  desc = 'desc'
}

export interface IDashboardAction {
  id?: string;
  title: string;
  name: string;
  style: object;
  icon?: any;
  color?: string | undefined;
  state: boolean;
  valueOnClick?: any;
  openFunction?: (
    { fileName }: { fileName: any },
    { fileSize }: { fileSize: number },
    { id }: { id: number },
    { setChecked }: { setChecked: any },
    { shapValueWanted }: { shapValueWanted: boolean },
    { isModels }: { isModels: boolean },
    { selected }: { selected: any },
    { setSelected }: { setSelected: any }
  ) => void;
}

export type GetComparator = (o: ArrangementOrder, o1: string) => (a: KeyedObject, b: KeyedObject) => number;

export interface HeadCell<T> {
  id: keyof T;
  numeric: boolean;
  label: string;
  disablePadding?: string | boolean | undefined;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify' | undefined;
}

export interface DashboardSearchProps {
  setChecked: any;
  page: any;
  setPage: any;
  isModelSetupModalOpen: any;
  setIsModelSetupModalOpen: any;
  setUserModelsArray?: any;
  rowDataStatus: any;
  setRowDataStatus: any;
  fileSize: any;
  rowData: any;
  setRowData: any;
  data: any;
  isModels: boolean;
  tableData: any;
  rows: Product[];
  setRows: React.Dispatch<SetStateAction<Product[]>>;
  hasUpload: boolean;
  searchProps: string[];
  numSelected: number;
  selected: any[];
  setSelected?: any;
  actions: IDashboardAction[];
  setActions?: (value: any) => void;
  setNewArray?: (value: any) => void;
  processing: boolean;
  setProcessing?: (value: any) => void;
  predictionTraining?: boolean;
  setPredictionTraining?: (value: any) => void;
  predictionProgress?: number;
  setPredictionProgress?: (value: any) => void;
}

export interface DashboardBodyProps {
  setChecked: any;
  checked: any;
  isModelSetupModalOpen?: any;
  setIsModelSetupModalOpen?: any;
  a?: boolean;
  setA?: any;
  isModels: boolean;
  rows: Product[];
  order: ArrangementOrder;
  orderBy: string;
  handleClick: (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>, data: any, name: string) => void;
  selected: string[];
  setSelected: any;
  page: number;
  rowsPerPage: number;
  actions: IDashboardAction[];
  tableHeaders: HeadCell<Product & Model>[];
  setActions?: (value: any) => void;
  processing: boolean;
  setProcessing?: (value: any) => void;
  predictionTraining?: boolean;
  setPredictionTraining?: (value: any) => void;
  predictionProgress?: number;
  setPredictionProgress?: (value: any) => void;
}

export interface DashboardActionsProps {
  setChecked: any;
  isModelSetupModalOpen: any;
  setShowConfirmDeployTooltip: any;
  showConfirmDeployTooltip: any;
  setIsModelSetupModalOpen: any;
  a?: boolean;
  setA?: any;
  isModels: boolean;
  openActions: number;
  setOpenActions: React.Dispatch<SetStateAction<number>>;
  row: Product & Model;
  actions: IDashboardAction[];
  setActions?: (value: any) => void;
  selected: any;
  setSelected: any;
  shapValueWanted?: any;
  setShapValueWanted?: any;
}
