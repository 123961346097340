import React, { useEffect } from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { IProgressProps } from '../../types';

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  bar: {
    borderRadius: 5
  }
}))(LinearProgress);

const Progress = (props: IProgressProps) => {
  const { progress, setProgress, predictionProgress, setPredictionProgress, uploadStatus } = props;
  const theme = useTheme();
  // const [progress, setProgress] = React.useState(0);

  const progressRef = React.useRef(() => {});

  // useEffect(() => {
  //   progressRef.current = () => {
  //     if (progress > 100) {
  //       setProgress(0);
  //     } else {
  //       const diff = Math.random() * 10;
  //
  //       setProgress(progress + diff);
  //     }
  //   };
  // });
  //
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     progressRef.current();
  //   }, 500);
  //
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return (
    <div>
      <Grid container position='relative' spacing={2} alignItems="center" justifyContent="center">
        <Grid item position='absolute' top='-11px' left='0' paddingTop='22px !important'>
          <Typography  variant="caption">{uploadStatus.length > 0 ? uploadStatus : ''}</Typography>
        </Grid>
        <Grid paddingTop='27px !important' item xs sx={{ color: theme.palette.success.main }}>
          <BorderLinearProgress color="inherit" variant="determinate" value={progress} />
        </Grid>
        <Grid item>
          <Typography variant="h6">{progress}%</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Progress;
