import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setSnowflakePushDataProgressData } = slice.actions;

const getSnowflakePushDataProgressData = (url: any) => async () => {
  try {
    const response = await API.snowflakePushDataProgress.getSnowflakePushDataProgress(url);
    dispatch(setSnowflakePushDataProgressData(response.data));
    return { response, status: response.status, data: response.data };
  } catch (error) {}
};

export default {
  getSnowflakePushDataProgressData
};
