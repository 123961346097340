import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Button, CardContent, Grid, InputAdornment, TextField } from '@mui/material';

import { DashboardSearchProps, KeyedObject } from '../../types';
// import { readString } from 'react-papaparse';

import DashboardToolbar from './DashboardToolbar';
import { dispatch, useAppSelector } from '../../store/hooks';
import { resourcesSelector } from '../../store/slices/resources';
import { toast } from 'react-toastify';
import { uploadMiddleware, uploadSelector } from '../../store/slices/upload';
import { uploadStatusMiddleware, uploadStatusSelector } from '../../store/slices/uploadStatus';
import { storageMiddleware } from '../../store/slices/storage';
import { userModelsMiddleware, userModelsSelector } from '../../store/slices/userModels';
import { Storage, CloudUpload } from '@mui/icons-material';
import DatasetModalComponent from '../../modals/DatasetModal/DatasetModal.component';
import { uploadModelMiddleware, uploadModelSelector } from '../../store/slices/uploadModel';
import { setUploadModelData, setUploadModelStatus } from './../../store/slices/uploadModel/middleware';
import { uploadModelStatusMiddleware } from '../../store/slices/uploadModelStatus';
import { removeLastExtension } from '../../helpers/removeExtension';
import axios from 'axios';
import API from '../../manager/API';
import ProcessingUploadModal from '../../pages/ProcessingPage/processingUploadModal/processingUploadModal.component';

const DashboardSearch = ({
  page,
  setPage,
  isModelSetupModalOpen,
  setIsModelSetupModalOpen,
  setUserModelsArray,
  fileSize,
  setNewArray,
  tableData,
  rows,
  setRows,
  hasUpload,
  searchProps,
  numSelected,
  selected,
  setSelected,
  actions,
  setActions,
  processing,
  setProcessing,
  isModels,
  rowData,
  setRowData,
  data,
  rowDataStatus,
  setRowDataStatus,
  setChecked
}: DashboardSearchProps) => {
  const [search, setSearch] = useState<string>('');
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadingModel, setUploadingModel] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const [uploadedFileSize, setUploadedFileSize] = useState<any>(0);
  const products = tableData;
  const getAllResourcesURL = `/datasource/all-files`;
  const getUserModelsURL = `/datasource/user_models`;
  const { uploadModelData, uploadModelStatus } = useAppSelector(uploadModelSelector.uploadModelData);
  const [snowflakeProgress, setSnowflakeProgress] = useState<number>(0);
  const [snowflakeStatus, setSnowflakeStatus] = useState<string>('');

  useEffect(() => {
    setUploadModelData({});
    setUploadModelStatus(0);
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSnowflakeProgress(0)
    setSnowflakeStatus('')
  };

  const datasetRow = {
    id: 0,
    ceration_date: '2023-02-28 16:10:24+00:00',
    filename: '',
    shared_user: [],
    size: 1024,
    user: 'Armen Panyan',
    disabled: true
  };

  const handleFileUpload = async (e: any) => {
    const currentTarget = e.currentTarget;
    const uploadFileUrl = `/blob_file/stream_upload`;
    const file = e.target.files[0];
    const fileName = file.name.trim();
    const fileSize = file.size;
    setUploadedFileSize(fileSize)
    setUploadedFileName(fileName)

    // const newRow = {
    //   id: 0,
    //   filename: file.name,
    //   shared_user: [],
    //   size: '',
    //   user: 'Armen Panyan',
    //   disabled: true
    // };

    if (!file) {
      toast.error('No file selected');
      return;
    }


    const fileExtension = fileName.split('.').pop().toLowerCase();

    if (fileExtension !== 'csv') {
      toast.error('Please select a .csv file');
      currentTarget.value = ''; // Clear the file input
      handleCloseModal()
      return;
    }

    try {
      setUploading(true);

      // if (setNewArray && file.name.endsWith('.csv')) {
      //   const x = tableData.unshift(newRow);
      //   setNewArray(tableData);
      // }

      const formData = new FormData();
      const cleanedFile = new File([file], fileName);
      formData.append('file', cleanedFile);
      setModalOpen(true);

      try {
        const response = await dispatch(uploadMiddleware.uploadFile(formData, uploadFileUrl));
        if(response.status !== 200){
          if (setProcessing) {
            setProcessing(false);
          }
          setUploading(false)
          if (setNewArray) {
            const x = tableData.shift();
            setNewArray(tableData);
          }
          handleCloseModal()
        }

        const uploadFileData = response.data;

        setUploading(false);

        if (uploadFileData && Object.keys(uploadFileData).length > 0 && uploadFileData?.status_update) {
          const uploadStatusUrl = `/blob_file/data_status/${uploadFileData.status_update}`;
          const getUploadProgressURL = `/blob_file/stream_upload_progress/${uploadFileData.status_update}`;
          let intervalId: any = setInterval(async () => {
            try {
              const uploadFileStatusData = await dispatch(
                uploadStatusMiddleware.uploadStatus(uploadFileData.status_update, uploadStatusUrl, getAllResourcesURL)
              );
              const dataOfUploadProgress = await API.uploadProgress.getUploadProgress(getUploadProgressURL);

              if (uploadFileStatusData.status === 200) {
                if (
                  dataOfUploadProgress &&
                  dataOfUploadProgress.data.progress &&
                  dataOfUploadProgress.data.progress.length > 0
                ) {
                  setUploadProgress(dataOfUploadProgress.data.progress);
                  setUploadStatus(dataOfUploadProgress.data.description);
                }
                if (uploadFileStatusData.data.queue_state === 'SUCCESS') {
                  if (setProcessing) {
                    setProcessing(false);
                    toast.success('Upload successful!');
                  }
                  const getStorageURL = `blob_file/get_user_storage_size`;
                  dispatch(storageMiddleware.getStorage(getStorageURL));
                  clearInterval(intervalId);
                  handleCloseModal()
                } else if (
                  uploadFileStatusData.data.queue_state === 'FAILURE' ||
                  (uploadFileStatusData && Object.keys(uploadFileStatusData).length === 0)
                ) {
                  handleCloseModal()
                  clearInterval(intervalId);
                  if (uploadFileStatusData && Object.keys(uploadFileStatusData).length === 0) {
                    handleCloseModal()
                    toast.warn(removeLastExtension(uploadFileStatusData.data.message));
                     if (setProcessing) {
                    setProcessing(false);
                  }
                  } else {
                    toast.error(removeLastExtension(uploadFileStatusData.data.message));
                     if (setProcessing) {
                    setProcessing(false);
                       handleCloseModal()
                  }
                  }
                  if (setProcessing) {
                    setProcessing(false);
                  }
                  if (setNewArray) {
                    const x = tableData.shift();
                    setNewArray(tableData);
                  }
                }
              } else {
                clearInterval(intervalId);
                toast.error(removeLastExtension(uploadFileStatusData.data.message));
                handleCloseModal()
                if (setProcessing) {
                  setProcessing(false);
                }
                if (setNewArray) {
                  const x = tableData.shift();
                  setNewArray(tableData);
                }
              }
            } catch (error: any) {
              clearInterval(intervalId);
              toast.error(removeLastExtension(error?.response.data.message));
              handleCloseModal()
              if (setProcessing) {
                setProcessing(false);
              }
              if (setNewArray) {
                const x = tableData.shift();
                setNewArray(tableData);
              }
            }
          }, 5000);
        }
      } catch (error: any) {
        console.error('Upload failed with error:', (error as Error).message);
        toast.error(removeLastExtension(error?.response.data.message));
        handleCloseModal()
        setUploading(false);
        tableData.shift();
        if (setNewArray) {
          setNewArray(tableData);
        }
      }
    } catch (error) {
      console.error('CSV validation failed:', (error as Error).message);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close the modal
    setUploadProgress(0)
    setUploadStatus('')
    setUploadedFileName('')
    setUploadedFileSize(0)
  };

  const handleModelUpload = async (e: any) => {
    const currentTarget = e.currentTarget;
    const uploadModelUrl = `/model_import/model_upload`;
    const file = e.target.files[0];
    const fileName = file.name.trim();

    const newRow = {
      id: 0,
      // ceration_date: '2023-02-28 16:10:24+00:00',
      filename: file.name,
      shared_user: [],
      size: '',
      user: 'Armen Panyan',
      disabled: true
    };

    if (
      setUserModelsArray &&
      (file.name.endsWith('.pkl') ||
        file.name.endsWith('.h5') ||
        file.name.endsWith('.pickle') ||
        file.name.endsWith('.p'))
    ) {
      const x = tableData.unshift(newRow);
      setUserModelsArray(tableData);
    }

    if (
      !file.name.endsWith('.pkl') &&
      !file.name.endsWith('.h5') &&
      !file.name.endsWith('.pickle') &&
      !file.name.endsWith('.p')
    ) {
      toast.error('Please select a .pkl or .h5 file');
      currentTarget.value = '';
      return;
    }

    setUploadingModel(true);
    const data = new FormData();
    const cleanedFile = new File([file], fileName);
    data.append('file', cleanedFile);
    data.append('file', file);
    const uploadModelData = await dispatch(uploadModelMiddleware.uploadModel(data, uploadModelUrl));
    setUploadingModel(false);

    if (uploadModelData?.status === 200) {
      if (setProcessing) {
        setProcessing(false);
      }
    } else {
      tableData.shift();
      toast.error(removeLastExtension(uploadModelData?.data.message));
      if (setUserModelsArray) {
        setUserModelsArray(tableData);
      }
      if (setProcessing) {
        setProcessing(false);
      }
    }

    if (uploadModelData && Object.keys(uploadModelData).length > 0 && uploadModelData?.data?.status_update) {
      const uploadModelStatusUrl = `/model_import/model_import_status/${uploadModelData.data.status_update}`;
      let intervalId: any = setInterval(async () => {
        try {
          const uploadModelStatusData = await dispatch(
            uploadModelStatusMiddleware.uploadModelStatus(uploadModelStatusUrl)
          );

          if (uploadModelStatusData.status === 200) {
            if (uploadModelStatusData.data.queue_state === 'SUCCESS') {
              if (setProcessing) {
                setProcessing(false);
                toast.success('Upload successful!');
              }
              dispatch(userModelsMiddleware.getUserModels(getUserModelsURL));
              const getStorageURL = `blob_file/get_user_storage_size`;
              dispatch(storageMiddleware.getStorage(getStorageURL));
              clearInterval(intervalId);
            } else if (
              uploadModelStatusData.data.queue_state === 'FAILURE' ||
              (uploadModelStatusData && Object.keys(uploadModelStatusData).length === 0)
            ) {
              clearInterval(intervalId);
              if (uploadModelStatusData && Object.keys(uploadModelStatusData).length === 0) {
                toast.warn('You will see your trained model after refresh');
              } else {
                toast.error(removeLastExtension(uploadModelStatusData.data.message));
              }
              if (setProcessing) {
                setProcessing(false);
              }
              if (setUserModelsArray) {
                const x = tableData.shift();
                setUserModelsArray(tableData);
              }
            }
          } else {
            clearInterval(intervalId);
            toast.error(removeLastExtension(uploadModelStatusData.data.message));
            if (setProcessing) {
              setProcessing(false);
            }
            if (setUserModelsArray) {
              const x = tableData.shift();
              setUserModelsArray(tableData);
            }
          }
        } catch (error: any) {
          clearInterval(intervalId);
          toast.error(removeLastExtension(error?.response.data.message));
          if (setProcessing) {
            setProcessing(false);
          }
          if (setUserModelsArray) {
            const x = tableData.shift();
            setUserModelsArray(tableData);
          }
        }
      }, 5000);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    const newString = event?.target.value;
    setSearch(newString ?? '');

    // Always filter from the full dataset `products` rather than `rows`
    if (newString) {
      const newRows = products.filter((row: KeyedObject) => {
        const properties = searchProps;
        return properties.some((property) =>
          removeLastExtension(row[property])?.toString().toLowerCase().includes(newString.toLowerCase())
        );
      });
      setRows(newRows);
      setPage(0);
    } else {
      setRows(products); // Reset to full dataset when there's no search query
      setPage(0);
    }
  };

  useEffect(() => {
    setRows(products); // Initialize rows with full dataset
    setPage(0); // Ensure we start from the first page
  }, [products]);

  return (
    <CardContent>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={2}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              )
            }}
            onChange={handleSearch}
            placeholder={isModels ? 'Search Model' : 'Search Dataset'}
            value={search}
            size="small"
          />
        </Grid>
        {tableData.length > 0 && numSelected > 0 && (
          <Grid item xs={12} sm={10}>
            <DashboardToolbar
              setChecked={setChecked}
              isModelSetupModalOpen={isModelSetupModalOpen}
              setIsModelSetupModalOpen={setIsModelSetupModalOpen}
              rowDataStatus={rowDataStatus}
              setRowDataStatus={setRowDataStatus}
              fileSize={fileSize}
              rowData={rowData}
              setRowData={setRowData}
              data={data}
              isModels={isModels}
              selected={selected}
              setSelected={setSelected}
              numSelected={selected.length}
              actions={actions}
              setActions={setActions}
            />
          </Grid>
        )}
        {!isModels && (tableData.length === 0 || (hasUpload && numSelected <= 0)) && (
          <Grid item style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '50px' }}>
            <Button
              component="label"
              variant="contained"
              size="large"
              style={{ color: '#fff', backgroundColor: '#202090', marginRight: '10px' }}
              startIcon={<CloudUpload />}
            >
              Upload Dataset
              {!uploading && <input type="file" hidden onChange={handleFileUpload} />}
            </Button>
            {/*<Button*/}
            {/*  component="label"*/}
            {/*  variant="contained"*/}
            {/*  size="large"*/}
            {/*  style={{ color: '#fff', backgroundColor: '#202090', marginRight: '10px' }}*/}
            {/*  startIcon={<CloudUpload />}*/}
            {/*>*/}
            {/*  Upload Dataset1*/}
            {/*  {!uploading && <input type="file" hidden onChange={handleFileUpload1} />}*/}
            {/*</Button>*/}

            <Button
              component="label"
              variant="contained"
              size="large"
              style={{ color: '#fff', backgroundColor: '#202090' }}
              startIcon={<Storage />}
              onClick={openModal}
            >
              Get Dataset From DBS
            </Button>
            {isModalOpen && (
              <DatasetModalComponent
                setSnowflakeStatus={setSnowflakeStatus}
                setSnowflakeProgress={setSnowflakeProgress}
                snowflakeStatus={snowflakeStatus}
                snowflakeProgress={snowflakeProgress}
                setNewArray={setNewArray}
                rowData={datasetRow}
                data={tableData}
                onClose={closeModal}
                title="Get Dataset From DB"
              />
            )}
          </Grid>
        )}
        {isModels && (tableData.length === 0 || numSelected <= 0) && (
          <Grid item xs={12} sm={4} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '50px' }}>
            <Button
              component="label"
              variant="contained"
              size="large"
              style={{ color: '#fff', backgroundColor: '#202090', marginRight: '10px' }}
              startIcon={<CloudUpload />}
            >
              Upload Model
              {!uploadingModel && <input type="file" hidden onChange={handleModelUpload} />}
            </Button>
          </Grid>
        )}
        <ProcessingUploadModal
          uploadedFileName={uploadedFileName}
          uploadedFileSize={uploadedFileSize}
          uploadStatus={uploadStatus}
          setUploadStatus={setUploadStatus}
          progress={uploadProgress}
          setProgress={setUploadProgress}
          onContinue={handleCloseModal}
          open={modalOpen}
          onClose={handleCloseModal}
          upDat={'uploadData'}
        />
      </Grid>
    </CardContent>
  );
};

export default DashboardSearch;
