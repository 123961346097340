import API from '../../../manager/API';
import { dispatch } from '../../hooks';
import slice from './slice';

const { setUploadProgressData } = slice.actions;

const getUploadProgress = (url: any) => async () => {
  try {
    const response = await API.uploadProgress.getUploadProgress(url);
    dispatch(setUploadProgressData(response.data));
    return { response, status: response.status, data: response.data };
  } catch (error) {}
};

export default {
  getUploadProgress
};
