import React, { useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { IProcessingColoredHistogram } from './processingColoredHistogram.models';
import { Search } from '@mui/icons-material';
import {
  autocompleteIconStyles,
  autocompleteLiStyles,
  autocompletePlaceholderStyles,
  autocompleteStyles,
  containerStyles,
  inputStyles,
  labelStyles
} from './processingColoredHistogram.styles';
import ProcessingCartBar from '../processingChartBar/processingChartBar.component';

const ProcessingColoredHistogram: React.FC<IProcessingColoredHistogram> = ({
                                                                             columnName,
                                                                             histogramChartBarData,
                                                                             defaultChartBarData
                                                                           }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(''); // Initial value is empty string

  const handleAutocompleteChange = (event: any, newValue: any) => {
    setInputValue(newValue?.value || ''); // Update inputValue with the selected value
  };

  const keysArray = histogramChartBarData &&
    histogramChartBarData[columnName] && Object.keys(histogramChartBarData[columnName]).length > 0 &&
    Object.keys(histogramChartBarData[columnName]);

  return (
    <>
      {keysArray && (
        <>
          <div style={containerStyles}>
            <div style={inputStyles}>
              <Autocomplete
                options={keysArray && keysArray.map((key: any) => ({
                  label: key,
                  value: key
                }))} // Map keysArray to options
                onChange={handleAutocompleteChange}
                value={
                  keysArray
                    .map((key: any) => ({ label: key, value: key })) // Map keysArray to match options format
                    .find((option: any) => option.value === inputValue) || null
                } // Find the object matching inputValue
                loading={loading}
                noOptionsText={loading ? <CircularProgress size={24} /> : 'No options'}
                sx={autocompleteStyles}
                renderOption={(props, option) => (
                  <li {...props} style={autocompleteLiStyles}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Search option'
                    label=''
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <Search style={autocompleteIconStyles} />
                          {params.InputProps.startAdornment}
                        </>
                      ),
                      style: autocompleteLiStyles
                    }}
                    sx={autocompletePlaceholderStyles}
                  />
                )}
                componentsProps={{
                  clearIndicator: {
                    onClick: () => setInputValue('')
                  }
                }}
                disableClearable={!inputValue}
              />
            </div>
          </div>
          <ProcessingCartBar
            inputValue={inputValue}
            histogramChartBarData={histogramChartBarData[columnName][inputValue]}
            defaultChartBarData={defaultChartBarData}
            label=''
            open={true}
          />
        </>
      )}
    </>
  );
};

export default ProcessingColoredHistogram;
